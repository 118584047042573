/**
 * Direttiva che forza l'autofocus negli input
 */
angular.module("app").directive('focusMe', function () {
	return {
		link: function (scope: any, element: any, attrs: any) {
			scope.$watch(attrs.focusMe, function (value: any) {
				if (value === true) {
					element[0].focus();
					//    element[0].select();
				}
			});
		}
	};
});